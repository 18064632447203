import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({ standalone: true, selector: 'input[niSetFocus],button[niSetFocus]' })
export class SetFocusDirective implements AfterViewInit {
  private readonly el: ElementRef = inject(ElementRef);

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 200);
  }
}
